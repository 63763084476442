import { Injectable } from '@angular/core';

import { Widget } from 'src/models';

import { GenericObjectsService } from './generic-objects.service';

@Injectable({
  providedIn: 'root',
})
export class WidgetsService extends GenericObjectsService<Widget> {
  protected baseUrl = '/widgets';
}
