<ion-split-pane contentId="cx-home-content" [ngClass]="{ 'skinny-left-nav-split-pane': !shrink }">
  <ion-menu
    mode="md"
    contentId="cx-home-content"
    type="overlay"
    class="left-nav-menu"
    [ngClass]="{ 'skinny-left-nav-menu': !shrink }"
  >
    <ion-content>
      <div style="height: 100%">
        <!-- <a routerLink="dashboard/home" routerDirection="root" class="hidden-md">
            <img src="/assets/imgs/unthread-logo-trimmed.png" class="logo" />
          </a> -->
        <ion-list class="inbox-list transparent-bg">
          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">
            <ion-item
              routerDirection="root"
              [routerLink]="[p.url]"
              routerLinkActive="selected"
              #rla="routerLinkActive"
              lines="none"
              detail="false"
              (mouseenter)="mouseOver(i)"
              (mouseleave)="mouseOut(i)"
              (click)="pageClick($event, p)"
              class="nav-item"
              [ngClass]="{ 'skinny-menu-item': shrink }"
            >
              <ion-icon
                [slot]="!shrink ? 'start' : ''"
                class="nav-icon"
                [name]="rla.isActive ? p.icon : p.icon + '-outline'"
              ></ion-icon>
              <div class="hover-indicator animate-in" *ngIf="shrink && i === hoverIndex">{{ p.title }}</div>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
            <div *ngIf="rla.isActive && p.subpages" class="subnav-container menu-list" style="overflow: auto">
              <div class="animate-in">
                <p class="subnav-header" style="margin-bottom: 10px; margin-top: 14px">{{ p.title }}</p>
                <div class="space-below" *ngFor="let subpageGroup of p.subpages">
                  <p
                    *ngIf="subpageGroup.title"
                    class="subnav-header"
                    (click)="subpageGroup.isOpen = !subpageGroup.isOpen"
                    style="cursor: pointer"
                  >
                    <ion-icon
                      [name]="subpageGroup.isOpen ? 'caret-down-outline' : 'caret-forward-outline'"
                      class="collapse-icon"
                    ></ion-icon>
                    {{ subpageGroup.title }}
                  </p>
                  <div
                    *ngIf="!subpageGroup.title || subpageGroup.isOpen"
                    [ngClass]="{ 'indent-left': subpageGroup.isOpen }"
                  >
                    <ng-container *ngFor="let subpage of subpageGroup.subpages; let i = index">
                      <ion-item
                        routerDirection="root"
                        [routerLink]="[subpage.url]"
                        routerLinkActive="selected"
                        #rla="routerLinkActive"
                        class="subnav-item"
                        lines="none"
                        detail="false"
                        [queryParams]="{ view: getCurrentView() }"
                      >
                        <ion-label class="subnav-label" [title]="subpage.title">
                          <ion-icon
                            slot="start"
                            class="subnav-icon"
                            *ngIf="subpage.icon"
                            [name]="rla.isActive ? subpage.icon : subpage.icon + '-outline'"
                          ></ion-icon>
                          {{ subpage.title }}
                          <span *ngIf="subpage.isNew">✨</span>
                        </ion-label>
                        <ion-icon
                          class="overflow-icon"
                          slot="end"
                          name="ellipsis-vertical"
                          *ngIf="subpage.type === 'savedFilter' && rla.isActive"
                          (click)="showPageOptions(subpage)"
                        ></ion-icon>
                      </ion-item>
                    </ng-container>
                  </div>
                </div>
              </div>

              <div
                class="pill-tab-container"
                style="margin: 10px; display: inline-flex; position: fixed; bottom: 10px; z-index: 99"
                [hidden]="!showInboxLayoutOption"
              >
                <div
                  class="round-pill-tab"
                  [ngClass]="{
                    'round-pill-tab-active': inboxLayoutType === 'grid'
                  }"
                  (click)="setLayoutType('grid')"
                  ngxTippy
                  [tippyProps]="{
                    arrow: false,
                    placement: 'top'
                  }"
                  data-tippy-content="Use kanban layout"
                >
                  <ion-icon name="custom-kanban"></ion-icon>
                </div>
                <div
                  class="round-pill-tab round-pill-tab-left"
                  [ngClass]="{
                    'round-pill-tab-active': inboxLayoutType === 'table'
                  }"
                  (click)="setLayoutType('table')"
                  ngxTippy
                  [tippyProps]="{
                    arrow: false,
                    placement: 'top'
                  }"
                  data-tippy-content="Use table layout"
                >
                  <ion-icon name="list"></ion-icon>
                </div>
                <div
                  class="round-pill-tab"
                  [ngClass]="{
                    'round-pill-tab-active': inboxLayoutType === 'sidebar'
                  }"
                  (click)="setLayoutType('sidebar')"
                  ngxTippy
                  [tippyProps]="{
                    arrow: false,
                    placement: 'top'
                  }"
                  data-tippy-content="Use sidebar layout"
                >
                  <ion-icon name="custom-sidebar"></ion-icon>
                </div>
              </div>
            </div>
          </ion-menu-toggle>
        </ion-list>
        <ion-list class="transparent-bg" style="position: absolute; bottom: 5px; left: 6px">
          <ion-item
            lines="none"
            detail="false"
            button="true"
            (click)="toggleDarkMode()"
            class="skinny-menu-item"
            ngxTippy
            [tippyProps]="{
              arrow: false,
              placement: 'right'
            }"
            data-tippy-content="Toggle dark mode"
          >
            <ion-icon
              [slot]="!shrink ? 'start' : ''"
              class="nav-icon"
              [name]="darkMode ? 'moon-outline' : 'sunny-outline'"
            ></ion-icon>
          </ion-item>
        </ion-list>
      </div>
    </ion-content>
  </ion-menu>
  <ion-router-outlet
    id="cx-home-content"
    ion-router-outlet
    [environmentInjector]="environmentInjector"
  ></ion-router-outlet>
</ion-split-pane>
