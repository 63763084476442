import { CommonModule } from '@angular/common';
import { AfterViewChecked, Component, Input, input, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { IonicModule, NavParams, PopoverController } from '@ionic/angular';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';

import { AvatarModule } from '../avatar/avatar.module';

interface Item {
  title: string;
  image?: string;
  id: string;
  object?: any;
}

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule, RouterModule, AvatarModule],
  selector: 'app-searchable-popover',
  templateUrl: './searchable-popover.component.html',
  styleUrls: ['./searchable-popover.component.scss'],
})
export class SearchablePopoverComponent implements OnInit, AfterViewChecked {
  componentView: string;

  @ViewChild('searchInput') searchInput;
  resultSearchRx = new Subject<string>();

  selectedItemId: string;
  searchText: string;
  items: Item[];
  isLoading = false;
  showImages = false;
  showSearch = true;
  placeholderLink: string;
  highlightedItemIndex = -1;
  isViewInitialized = false;
  initialSearchText: string;
  callback: (id: string, title: string, object?: any) => {};
  loadData: (searchText: string) => Promise<Item[]>;
  dynamicCreationCallback: (searchText: string) => {};

  constructor(
    private navParams: NavParams,
    private popoverCtrl: PopoverController,
  ) {}

  ngOnInit() {
    this.selectedItemId = this.navParams.get('selectedItemId');
    this.items = this.navParams.get('items');
    this.resultSearchRx.pipe(debounceTime(150)).subscribe(async () => {
      try {
        this.isLoading = true;

        this.items = await this.loadData(this.searchText);
        if (this.items?.length) {
          this.highlightedItemIndex = 0;
        }

        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        console.warn('Error loading data: ', e);
      }
    });

    if (this.initialSearchText) {
      this.searchText = this.initialSearchText;
    }

    this.load();
  }

  ngAfterViewChecked() {
    // FUCK IONIC TEXT FIELDS
    // WHY DO THEY NEED THESE HACKS TO FOCUS
    // TAKE SOME ADDERALL FFS
    if (!this.isViewInitialized) {
      this.searchInput.setFocus();
    }

    setTimeout(() => {
      this.isViewInitialized = true;
    }, 500);
  }

  async load() {
    this.resultSearchRx.next(this.searchText);
  }

  selectItem(item: Item) {
    this.callback(item.id, item.title, item.object);

    this.dismiss();
  }

  getClassName() {
    if (this.componentView) {
      return { [this.componentView]: true };
    }
    return {};
  }

  highlightItemAbove() {
    this.highlightedItemIndex--;
  }

  highlightItemBelow() {
    if (this.highlightedItemIndex < -1 || this.highlightedItemIndex > this.items.length) {
      this.highlightedItemIndex = -1;
    }

    this.highlightedItemIndex++;

    if (this.highlightedItemIndex >= this.items.length) {
      this.highlightedItemIndex = 0;
    }
  }

  selectHighlightedItem() {
    const item = this.items[this.highlightedItemIndex];
    if (item) {
      this.selectItem(item);
    }
  }

  createNewItem() {
    this.dynamicCreationCallback(this.searchText);
    this.dismiss();
  }

  dismiss() {
    this.popoverCtrl.dismiss();
  }

  handleFilters() {
    this.load();
  }
}
