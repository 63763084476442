import { Component, EnvironmentInjector, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { AlertController, NavController, PopoverController } from '@ionic/angular';

import { environment } from 'src/environments/environment.js';
import { ExternalChatConnection, InboundEmailRule, SavedFilter, SavedReport, WhereClause, Widget } from 'src/models';
import { AnalyticsService } from 'src/services/analytics.service';
import { ApiService } from 'src/services/api.service';
import { AuthService } from 'src/services/auth.service';
import { ExternalChatConnectionsService } from 'src/services/external-chat-connections.service';
import { InboundEmailrulesService } from 'src/services/inbound-email-rules.service';
import { UsersService } from 'src/services/users.service';
import { WidgetsService } from 'src/services/widgets.service';

import { ActionDropdownComponent, ActionDropdownType } from '../components/action-dropdown/action-dropdown.component';
import {
  EXTERNAL_CHAT_CONNECTION_TYPES,
  INBOX_TYPES,
  PROJECT_MEMBER_ROLES,
  ProjectMemberRole,
  routes,
} from '../constants';
import { isDev } from '../utils/utils.js';

const ROOT = '/dashboard';

export type MenuPage = {
  title: string;
  url: string;
  spaceBelow?: boolean;
  icon?: string | null;
  id?: string;
  type?: string;
  subpages?: any[];
  isNew?: boolean;
  header?: string;
};

export const PATHS = {
  HOME: `${ROOT}/home`,
  INBOX: `${ROOT}/inbox`,
  CUSTOMERS: `${ROOT}/customers`,
  ACCOUNTS: `${ROOT}/accounts`,
  CHANNELS: `${ROOT}/channels`,
  PEOPLE: `${ROOT}/people`,
  SETTINGS: `${ROOT}/settings`,
  ANALYTICS: `${ROOT}/analytics`,
  KNOWLEDGE_BASE: `${ROOT}/knowledge`,
  OUTBOUND: `${ROOT}/outbound`,
  TAGS: `${ROOT}/tags`,
};

export const INBOX_SUBPAGES = [
  {
    title: 'Assigned to me',
    url: `${PATHS.INBOX}/me`,
    spaceBelow: false,
    icon: 'person-circle',
    id: null,
    type: null,
  },
  // {
  //   title: 'Collaborating on',
  //   url: `${PATHS.INBOX}/collaborating-on`,
  //   spaceBelow: false,
  //   icon: 'person-circle',
  //   id: null,
  //   type: null,
  // },
  {
    title: 'Following',
    url: `${PATHS.INBOX}/following`,
    spaceBelow: false,
    icon: 'notifications-circle',
    id: null,
    type: null,
  },
  {
    title: 'All',
    url: `${PATHS.INBOX}/all`,
    spaceBelow: false,
    icon: 'people-circle',
    id: null,
    type: null,
  },
  {
    title: 'Unassigned',
    url: `${PATHS.INBOX}/unassigned`,
    spaceBelow: false,
    icon: 'ellipse',
    id: null,
    type: null,
  },
  {
    title: 'Closed',
    url: `${PATHS.INBOX}/closed`,
    spaceBelow: false,
    icon: 'checkmark-circle',
    id: null,
    type: null,
  },
  {
    title: 'Intake',
    url: `${PATHS.INBOX}/${INBOX_TYPES.INTAKE}`,
    spaceBelow: false,
    icon: 'custom-intake',
    id: null,
    type: null,
  },
];

const REPORT_SUBPAGES = [
  {
    subpages: [
      {
        title: 'Performance',
        url: `/dashboard/analytics/performance`,
        icon: null, // 'timer',
      },
      {
        title: 'Volume',
        url: `/dashboard/analytics/volume`,
        icon: null, // 'stats-chart',
      },
      {
        title: 'AI',
        url: `/dashboard/analytics/ai`,
        icon: null, // 'stats-chart',
      },
    ],
  },
];

const PAGE_TYPES = {
  SAVED_FILTER: 'savedFilter',
  EMAIL_INBOX: 'emailInbox',
  WIDGET_INBOX: 'widgetInbox',
  MICROSOFT_TEAMS_INBOX: 'microsoftTeamsInbox',
  SAVED_REPORT: 'savedReport',
};

interface Subpage {
  title: string;
  url: string;
  icon?: string;
  isNew?: boolean;
  type?: string;
  id?: string;
}

interface SubpageGroup {
  title?: string;
  subpages: Subpage[];
  isOpen?: boolean;
}

@Component({
  selector: 'app-cx-home',
  templateUrl: './cx-home.component.html',
  styleUrls: ['./cx-home.component.scss'],
})
export class CxHomeComponent implements OnInit, OnDestroy {
  tab = 'general';
  shrink = true;
  appPages: MenuPage[] = [];
  hoverIndex = -1;
  darkMode: boolean;
  inboxLayoutType: 'grid' | 'table' | 'sidebar';
  showInboxLayoutOption = false;
  defaultInboxView: string;

  refetchInboxes = async (e: CustomEvent) => {
    if (e.detail?.view == 'inbox') {
      await this.getInboxes();

      const params = new URLSearchParams(window.location.search);

      this.analyticsService.trackEvent('Filter Buttons', 'Save new filter view');
      this.navController.navigateRoot(
        `/${routes.DASHBOARD}/${routes.INBOX}/filter/${e.detail?.id}${
          params.has('view') ? `?view=${params.get('view')}` : ''
        }`,
      );
    }
  };

  refetchReports = async (e: CustomEvent) => {
    await this.getSavedReports();
  };

  constructor(
    public authService: AuthService,
    private router: Router,
    private popoverCtrl: PopoverController,
    private alertCtrl: AlertController,
    public environmentInjector: EnvironmentInjector,
    private inboundEmailRulesService: InboundEmailrulesService,
    private widgetsService: WidgetsService,
    private analyticsService: AnalyticsService,
    private apiService: ApiService,
    private navController: NavController,
    private externalChatConnectionsService: ExternalChatConnectionsService,
    private usersService: UsersService,
  ) {
    const accountsEnabled = authService.tenant.accountsEnabled;

    this.appPages.push(
      {
        title: 'Home',
        url: PATHS.HOME,
        icon: 'home',
      },
      {
        title: 'Inbox',
        url: PATHS.INBOX,
        icon: 'file-tray',
        subpages: [
          {
            subpages: INBOX_SUBPAGES,
          },
        ],
      },
    );

    if (authService.isTenantAdmin) {
      this.appPages.push({
        title: 'Outbound',
        url: PATHS.OUTBOUND,
        icon: 'send',
        subpages: [
          {
            subpages: [
              {
                title: 'Broadcasts',
                url: `/dashboard/outbound/broadcasts`,
                icon: 'megaphone',
              },
              {
                title: 'Templates',
                url: `/dashboard/outbound/templates`,
                icon: 'shapes',
              },
              {
                title: 'Surveys',
                url: `/dashboard/outbound/surveys`,
                icon: 'pie-chart',
              },
            ],
          },
        ],
      });
    }

    this.appPages.push({
      title: 'Analytics',
      url: PATHS.ANALYTICS,
      icon: 'custom-analytics',
      subpages: REPORT_SUBPAGES,
    });

    if (authService.isTenantAdmin) {
      this.appPages.push({
        title: 'Knowledge',
        url: PATHS.KNOWLEDGE_BASE,
        icon: 'book',
        subpages: [
          {
            subpages: [
              {
                title: 'Articles',
                url: `/dashboard/knowledge/articles`,
                icon: null,
              },
              {
                title: 'Suggestions',
                url: `/dashboard/knowledge/suggestions`,
                icon: null,
              },
            ],
          },
        ],
      });
    }

    if (accountsEnabled) {
      this.appPages.push({
        title: 'Channels',
        url: PATHS.CHANNELS,
        icon: 'chatbubble',
        subpages: [
          {
            subpages: [
              {
                title: 'Slack',
                url: `/dashboard/channels/slack`,
                icon: 'logo-slack',
              },
              {
                title: 'Email',
                url: `/dashboard/channels/email`,
                icon: 'mail',
              },
              {
                title: 'Microsoft Teams',
                url: `/dashboard/channels/microsoft-teams`,
                icon: 'custom-teams',
              },
              {
                title: 'In-App Chat',
                url: `/dashboard/channels/in-app-chat`,
                icon: 'chatbubble',
              },
            ],
          },
        ],
      });

      this.appPages.push({
        title: 'Accounts',
        url: PATHS.ACCOUNTS,
        icon: 'business',
      });
    } else {
      this.appPages.push({
        title: 'Customers',
        url: PATHS.CUSTOMERS,
        icon: 'business',
      });
    }

    this.appPages.push({
      title: 'Tags',
      url: PATHS.TAGS,
      icon: 'pricetags',
    });

    const adminProjects = this.authService.projects.filter(
      (p) =>
        this.authService.isTenantAdmin ||
        this.authService.isTenantOwner ||
        ([PROJECT_MEMBER_ROLES.OWNER] as ProjectMemberRole[]).includes(p.role),
    );
    const isProjectAdmin = adminProjects.length > 0;

    if (authService.isTenantAdmin || isProjectAdmin) {
      const generalSubpageGroup: SubpageGroup = {
        subpages: [],
      };

      if (authService.isTenantAdmin) {
        generalSubpageGroup.subpages.push(
          {
            title: 'General',
            url: `/dashboard/settings/general`,
            icon: null,
          },
          {
            title: 'Slack',
            url: `/dashboard/settings/slack`,
            icon: null,
          },
          {
            title: 'AI',
            url: `/dashboard/settings/ai`,
            icon: null, // 'sparkles',
          },
          {
            title: 'Automations',
            url: `/dashboard/settings/automations`,
            icon: null,
          },
          {
            title: 'SLAs',
            url: `/dashboard/settings/slas`,
            icon: null,
          },
          {
            title: 'User Groups',
            url: `/dashboard/settings/user-groups`,
            icon: null,
          },
        );

        if (isDev() || this.authService.isSuperAdmin) {
          generalSubpageGroup.subpages.push({
            title: 'AI Tester',
            url: '/dashboard/settings/ai-tester',
            icon: null,
          });
        }
      }

      if (authService.isTenantAdmin) {
        generalSubpageGroup.subpages.push({
          title: 'Collaborator Types',
          url: `/dashboard/settings/collaborator-types`,
          icon: null, // 'book',
        });
      }

      generalSubpageGroup.subpages.push({
        title: 'Projects',
        url: `/dashboard/settings/project-list`,
        icon: null,
        isNew: true,
      });

      const projectSubpageGroups: SubpageGroup[] = [];
      let areAnyProjectsOpen = false;

      for (const project of adminProjects) {
        const isOpen = document.location.pathname.includes(project.id);

        areAnyProjectsOpen = areAnyProjectsOpen || isOpen;

        projectSubpageGroups.push({
          isOpen,
          title: project.name,
          subpages: [
            {
              title: 'Settings',
              url: `/dashboard/settings/projects/${project.id}/settings`,
              icon: 'cog',
            },
            {
              title: 'Members',
              url: `/dashboard/settings/projects/${project.id}/members`,
              icon: 'people',
            },
            environment.environment !== 'dev'
              ? undefined
              : {
                  title: 'Agent Topics',
                  url: `/dashboard/settings/projects/${project.id}/topics`,
                  icon: 'radio',
                },
            {
              title: 'Ticket Types',
              url: `/dashboard/settings/projects/${project.id}/ticket-types`,
              icon: 'ticket',
            },
            accountsEnabled
              ? undefined
              : {
                  title: 'Email',
                  url: `/dashboard/settings/projects/${project.id}/email`,
                  icon: 'mail',
                },
            accountsEnabled
              ? undefined
              : {
                  title: 'In-App Chat',
                  url: `/dashboard/settings/projects/${project.id}/in-app-chat`,
                  icon: 'chatbubble',
                },
            accountsEnabled
              ? undefined
              : {
                  title: 'Microsoft Teams',
                  url: `/dashboard/settings/projects/${project.id}/microsoft-teams`,
                  icon: 'custom-teams',
                },
            // Just hiding this until we can more clearly separate this from the global service accounts
            // {
            //   title: 'Service Accounts',
            //   url: `/dashboard/settings/projects/${project.id}/service-accounts`,
            //   icon: 'key',
            // },
          ].filter(Boolean),
        });
      }

      if (!areAnyProjectsOpen && projectSubpageGroups.length) {
        projectSubpageGroups[0].isOpen = true;
      }

      const adminSubpageGroup: SubpageGroup = {
        subpages: [
          {
            title: 'Apps & Integrations',
            url: `/dashboard/settings/apps`,
            icon: null, // 'apps',
          },
          {
            title: 'Service Accounts',
            url: '/dashboard/settings/service-accounts',
            icon: null, // 'id-card',
          },
          {
            title: 'Webhooks',
            url: '/dashboard/settings/webhooks',
            icon: null, // 'id-card',
          },
          {
            title: 'Security',
            url: `/dashboard/settings/security`,
            icon: null,
          },
          {
            title: 'Account Admin',
            url: `/dashboard/settings/admin`,
            icon: null, // 'lock-closed',
          },
        ],
      };

      const settingsSubpages: SubpageGroup[] = [generalSubpageGroup, ...projectSubpageGroups, adminSubpageGroup].filter(
        (group) => group.subpages.length,
      );

      if (authService.isTenantAdmin) {
        this.appPages.push({
          title: 'People',
          url: PATHS.PEOPLE,
          icon: 'people',
        });
      }

      this.appPages.push({
        title: 'Settings',
        url: PATHS.SETTINGS,
        icon: 'cog',
        subpages: settingsSubpages,
      });
    }

    this.router.events.subscribe((event: Event) => {
      this.hoverIndex = -1;

      if (event instanceof NavigationEnd) {
        this.shrink =
          router.url.includes(PATHS.ANALYTICS) ||
          router.url.includes(PATHS.SETTINGS) ||
          router.url.includes(PATHS.INBOX) ||
          router.url.includes(PATHS.OUTBOUND) ||
          router.url.includes(PATHS.KNOWLEDGE_BASE) ||
          router.url.includes(PATHS.CHANNELS);

        this.showInboxLayoutOption = router.url.includes(PATHS.INBOX) && window.innerWidth > 1300;
      }
    });

    this.darkMode = localStorage.getItem('theme') === 'dark';

    if (this.darkMode) {
      (window as any).$unthread?.inAppChat('setDarkMode', 'dark');
    }

    const inboxLayoutPreference: any = localStorage.getItem('inboxLayout');

    this.inboxLayoutType = inboxLayoutPreference ?? 'grid';

    this.getInboxes();
    this.getSavedReports();
  }

  ngOnInit() {
    // HACK: Simple way to communicate from the inbox componenent that a new saved filter was created.
    document.addEventListener('saved-filter', this.refetchInboxes, false);
    document.addEventListener('saved-report', this.refetchReports, false);
  }

  ngOnDestroy() {
    document.removeEventListener('saved-filter', this.refetchInboxes, false);
    document.removeEventListener('saved-report', this.refetchReports, false);
  }

  async getInboxes() {
    const baseWhere: WhereClause[] = this.authService.selectedProject
      ? [
          {
            field: 'projectId',
            operator: '==',
            value: this.authService.selectedProject.id,
          },
        ]
      : [];

    const [emailInboxes, widgetInboxes, microsoftTeamsInboxes, savedFilters, user] = await Promise.all([
      this.inboundEmailRulesService.list({ order: ['createdAt'], descending: false, where: baseWhere }),
      this.widgetsService.list({ order: ['createdAt'], descending: false, where: baseWhere }),
      this.externalChatConnectionsService.list({
        order: ['createdAt'],
        descending: false,
        where: [
          {
            field: 'type',
            operator: '==',
            value: EXTERNAL_CHAT_CONNECTION_TYPES.MICROSOFT_TEAMS,
          },
          ...baseWhere,
        ],
      }),
      this.apiService.getPromise('/saved-filters?view=inbox'),
      this.usersService.getUser(this.authService.userId),
    ]);

    // Set the defaultInboxView if configured on the user
    // otherwise, fall back to default routing behavior
    // see cx-home-routing.module.ts for more details
    this.defaultInboxView = user.defaultInboxView ?? null;

    const defaultInboxSubpageGroup: SubpageGroup = { subpages: INBOX_SUBPAGES };
    const emailInboxSubpageGroup: SubpageGroup = { subpages: [] };
    const widgetInboxSubpageGroup: SubpageGroup = { subpages: [] };
    const microsoftTeamsInboxSubpageGroup: SubpageGroup = { subpages: [] };
    const privateSavedFiltersSubpageGroup: SubpageGroup = { title: 'My views', subpages: [] };
    const publicSavedFiltersSubpageGroup: SubpageGroup = { title: 'Public views', subpages: [] };

    if (emailInboxes.data?.length) {
      emailInboxes.data.forEach((inbox: InboundEmailRule) => {
        if (inbox.customer?.name) {
          emailInboxSubpageGroup.subpages.push({
            title: `${inbox.customer.name}`,
            icon: 'mail',
            url: `${PATHS.INBOX}/email/${inbox.id}`,
            id: inbox.id,
            type: PAGE_TYPES.EMAIL_INBOX,
          });
        }
      });
    }

    if (widgetInboxes.data?.length) {
      widgetInboxes.data.forEach((inbox: Widget) => {
        widgetInboxSubpageGroup.subpages.push({
          title: `${inbox.name}`,
          url: `${PATHS.INBOX}/chat/${inbox.id}`,
          icon: 'chatbubble',
          id: inbox.id,
          type: PAGE_TYPES.WIDGET_INBOX,
        });
      });
    }

    if (microsoftTeamsInboxes.data?.length) {
      microsoftTeamsInboxes.data.forEach((inbox: ExternalChatConnection) => {
        if (inbox.customer?.name) {
          microsoftTeamsInboxSubpageGroup.subpages.push({
            title: `${inbox.customer.name}`,
            url: `${PATHS.INBOX}/microsoft-teams/${inbox.id}`,
            icon: 'custom-teams',
            id: inbox.id,
            type: PAGE_TYPES.MICROSOFT_TEAMS_INBOX,
          });
        }
      });
    }

    if ((savedFilters as Partial<SavedFilter>[]).length) {
      (savedFilters as Partial<SavedFilter>[]).forEach((savedFilter) => {
        const page: MenuPage = {
          title: `${savedFilter.name}`,
          icon: null,
          url: `${PATHS.INBOX}/filter/${savedFilter.id}`,
          spaceBelow: false,
          type: PAGE_TYPES.SAVED_FILTER,
          id: savedFilter.id,
        };

        if (savedFilter.userIds === null) {
          publicSavedFiltersSubpageGroup.subpages.push(page);

          if (document.location.pathname.includes(savedFilter.id)) {
            publicSavedFiltersSubpageGroup.isOpen = true;
          }
        } else {
          privateSavedFiltersSubpageGroup.subpages.push(page);

          if (document.location.pathname.includes(savedFilter.id)) {
            privateSavedFiltersSubpageGroup.isOpen = true;
          }
        }
      });
    }

    this.appPages[1].subpages = [
      defaultInboxSubpageGroup,
      emailInboxSubpageGroup,
      widgetInboxSubpageGroup,
      microsoftTeamsInboxSubpageGroup,
      privateSavedFiltersSubpageGroup,
      publicSavedFiltersSubpageGroup,
    ].filter((group) => group.subpages.length);
  }

  async getSavedReports() {
    const reports = await this.apiService.getPromise('/saved-reports');
    if ((reports as any).length) {
      const subpageGroup: SubpageGroup = { title: 'My reports', isOpen: true, subpages: [] };
      (reports as Partial<SavedReport>[]).forEach((savedReport) => {
        const page: MenuPage = {
          title: `${savedReport.name}`,
          icon: null,
          url: `${PATHS.ANALYTICS}/performance/reports/${savedReport.id}`,
          spaceBelow: false,
          id: savedReport.id,
        };

        subpageGroup.subpages.push(page);
      });

      this.appPages.forEach((ap) => {
        if (ap.url === PATHS.ANALYTICS) {
          ap.subpages[1] = subpageGroup;
        }
      });
    }
  }

  async showPageOptions(page: MenuPage) {
    if (!page.id) {
      return;
    }

    const ACTION_RENAME_FILTER = 'Rename';
    const ACTION_REMOVE_FILTER = 'Remove filter';
    const ACTION_SET_AS_DEFAULT_VIEW = 'Set as default view';
    const ACTION_MANAGE_EMAIL_INBOX = 'Manage email inbox';
    const ACTION_MANAGE_WIDGET_INBOX = 'Manage in-app chat';

    const options: ActionDropdownType[] = [];

    if (page.type === PAGE_TYPES.SAVED_FILTER) {
      options.push(
        { label: ACTION_RENAME_FILTER },
        { label: ACTION_REMOVE_FILTER },
        { label: ACTION_SET_AS_DEFAULT_VIEW },
      );
    } else if (page.type === PAGE_TYPES.EMAIL_INBOX) {
      options.push({ label: ACTION_MANAGE_EMAIL_INBOX });
    } else if (page.type === PAGE_TYPES.WIDGET_INBOX) {
      options.push({ label: ACTION_MANAGE_WIDGET_INBOX });
    }

    const popover = await this.popoverCtrl.create({
      component: ActionDropdownComponent,
      componentProps: {
        options,
        callback: async (_idx: number, label: string) => {
          popover.dismiss();

          if (label === ACTION_RENAME_FILTER) {
            const confirm = await this.alertCtrl.create({
              header: `Rename Filter`,
              inputs: [
                {
                  placeholder: 'Enter filter name',
                  attributes: {
                    maxLength: 320,
                  },
                  value: page.title,
                },
              ],
              buttons: [
                {
                  text: 'Cancel',
                  handler: () => {},
                },
                {
                  text: 'Save',
                  handler: async (data: any[]) => {
                    let name = data[0];

                    if (!name) {
                      return;
                    }

                    const result: any = await this.apiService.patchPromise(`/saved-filters/${page.id}`, {
                      name,
                    });
                    document.dispatchEvent(new CustomEvent('saved-filter', { detail: result }));
                  },
                },
              ],
            });
            confirm.present();
          } else if (label === ACTION_REMOVE_FILTER) {
            const result: any = await this.apiService.deletePromise(`/saved-filters/${page.id}`);
            await this.usersService.updateUser(this.authService.userId, {
              defaultInboxView: null,
            });
            document.dispatchEvent(new CustomEvent('saved-filter', { detail: result }));
            this.router.navigateByUrl(`/${routes.DASHBOARD}/${routes.INBOX}/all`);
            this.getInboxes();
          } else if (label === ACTION_SET_AS_DEFAULT_VIEW) {
            const result: any = await this.usersService.updateUser(this.authService.userId, {
              defaultInboxView: page.url,
            });
            document.dispatchEvent(new CustomEvent('saved-filter', { detail: result }));
          } else if (label === ACTION_MANAGE_EMAIL_INBOX) {
          } else if (label === ACTION_MANAGE_WIDGET_INBOX) {
          }
        },
      },
      showBackdrop: false,
      event,
    });
    popover.present();
  }

  mouseOver(index: number) {
    this.hoverIndex = index;
  }

  mouseOut(_index: number) {
    this.hoverIndex = -1;
  }

  setLayoutType(type: 'grid' | 'table' | 'sidebar') {
    this.inboxLayoutType = type;
    localStorage.setItem('inboxLayout', this.inboxLayoutType);

    this.analyticsService.trackEvent('Inbox', `Set Type to ${this.inboxLayoutType}`);

    document.dispatchEvent(new CustomEvent('inbox-layout-change', { detail: this.inboxLayoutType }));
  }

  toggleDarkMode() {
    this.darkMode = !this.darkMode;
    const value = this.darkMode ? 'dark' : 'light';

    localStorage.setItem('theme', value);
    document.documentElement.setAttribute('data-theme', this.darkMode ? 'dark' : '');

    (window as any).$unthread?.inAppChat('setDarkMode', value);

    this.analyticsService.trackEvent('Left Nav', this.darkMode ? 'Enable Dark Mode' : 'Enable Light Mode');
  }

  getCurrentView() {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    return params.get('view') ?? undefined;
  }

  async pageClick(event: MouseEvent, page: MenuPage) {
    if (page.title === 'Inbox' && this.defaultInboxView) {
      event.preventDefault(); // default inbox routing is handled by cx-home-routing.module.ts - we don't want this
      this.router.navigateByUrl(this.defaultInboxView);
    }
  }
}
