import { Injectable } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import { SearchablePopoverComponent } from 'src/app/components/searchable-popover/searchable-popover.component';
import {
  CONVERSATION_PRIORITY_VALUES,
  CONVERSATION_STATUS,
  DATE_OPTIONS,
  EXTERNAL_TASK_STATE,
  PROJECT_MEMBER_ROLES,
  PROJECT_MEMBER_ROLE_NAMES,
  ProjectMemberRole,
  SUPPORT_STEP_ASSIGNEE_TYPES,
  SUPPORT_STEP_KEYWORDS,
  routes,
} from 'src/app/constants';
import { INBOX_SUBPAGES, PATHS } from 'src/app/cx-home/cx-home.component';
import { environment } from 'src/environments/environment';
import {
  Automation,
  Conversation,
  Customer,
  Tag,
  Template,
  TicketType,
  WhereClause,
  Profile,
  ListResponse,
  ExternalCrmAccount,
  SlackGroup,
  SavedFilter,
  CustomField,
  TicketTypeField,
} from 'src/models';
import { Account } from 'src/models/accounts';
import { Entity } from 'src/models/entities';
import { Project } from 'src/models/projects';
import { TENANT_USER_ROLE_NAMES, TENANT_USER_ROLES } from 'src/models/users';
import { ApiService } from 'src/services/api.service';
import { ConversationsService } from 'src/services/conversations.service';
import { CustomersService } from 'src/services/customers.service';
import { SlackUserGroupsService } from 'src/services/slack-user-groups.service';
import { TagsService } from 'src/services/tags.service';
import { UserGroupsService } from 'src/services/user-groups.service';
import { UsersService } from 'src/services/users.service';

import { AuthService } from './auth.service';
import { AutomationsService } from './automations.service';
import { ProjectsService } from './projects.service';
import { ServiceAccountsService } from './service-accounts.service';
import { SlackService } from './slack.service';
import { TicketTypesService } from './ticket-types.service';

type PopoverSide = 'top' | 'bottom' | 'left' | 'right';

export const STATUS_OPTIONS = [
  {
    id: CONVERSATION_STATUS.OPEN,
    title: 'Needs response',
  },
  {
    id: CONVERSATION_STATUS.IN_PROGRESS,
    title: 'In progress',
  },
  {
    id: CONVERSATION_STATUS.ON_HOLD,
    title: 'On hold',
  },
  {
    id: CONVERSATION_STATUS.CLOSED,
    title: 'Closed',
  },
  {
    id: CONVERSATION_STATUS.INTAKE,
    title: 'Intake',
  },
];

export const EXTERNAL_TASK_FILTER_OPTIONS = [
  {
    id: EXTERNAL_TASK_STATE.ALL_TASKS_COMPLETE,
    title: 'All Tasks Complete',
  },
  {
    id: EXTERNAL_TASK_STATE.HAS_INCOMPLETE_TASKS,
    title: 'Some Tasks Incomplete',
  },
];

export const PRIORITY_OPTIONS = [
  {
    id: CONVERSATION_PRIORITY_VALUES.LOW,
    title: 'Low',
  },
  {
    id: CONVERSATION_PRIORITY_VALUES.MEDIUM,
    title: 'Medium',
  },
  {
    id: CONVERSATION_PRIORITY_VALUES.HIGH,
    title: 'High',
  },
  {
    id: CONVERSATION_PRIORITY_VALUES.CRITICAL,
    title: 'Critical',
  },
];

export const PROJECT_MEMBER_ROLE_OPTIONS = [
  {
    id: PROJECT_MEMBER_ROLES.OWNER,
    title: PROJECT_MEMBER_ROLE_NAMES[PROJECT_MEMBER_ROLES.OWNER],
  },
  {
    id: PROJECT_MEMBER_ROLES.AGENT,
    title: PROJECT_MEMBER_ROLE_NAMES[PROJECT_MEMBER_ROLES.AGENT],
  },
  {
    id: PROJECT_MEMBER_ROLES.STANDARD,
    title: PROJECT_MEMBER_ROLE_NAMES[PROJECT_MEMBER_ROLES.STANDARD],
  },
];

export const TENANT_USER_ROLE_OPTIONS = [
  {
    id: TENANT_USER_ROLES.OWNER,
    title: TENANT_USER_ROLE_NAMES[TENANT_USER_ROLES.OWNER],
  },
  {
    id: TENANT_USER_ROLES.ADMIN,
    title: TENANT_USER_ROLE_NAMES[TENANT_USER_ROLES.ADMIN],
  },
  {
    id: TENANT_USER_ROLES.STANDARD,
    title: TENANT_USER_ROLE_NAMES[TENANT_USER_ROLES.STANDARD],
  },
];

const NONE_TEXT = 'None';

function formatUserName(user: Partial<Profile>, connectedTeams: any[]) {
  const name = user.name?.trim() || user.email?.trim() || 'No Name';
  return `${name}${connectedTeams?.length > 1 && user.slackTeam?.name ? ` (${user.slackTeam.name})` : ''}`;
}

@Injectable({
  providedIn: 'root',
})
export class SearchablePopoverService {
  constructor(
    private popoverCtrl: PopoverController,
    private usersService: UsersService,
    private userGroupsService: UserGroupsService,
    private slackUserGroupsService: SlackUserGroupsService,
    private tagsService: TagsService,
    private apiService: ApiService,
    private customersService: CustomersService,
    private conversationsService: ConversationsService,
    private ticketTypesService: TicketTypesService,
    private automationsService: AutomationsService,
    private slackService: SlackService,
    private projectsService: ProjectsService,
    private serviceAccountsService: ServiceAccountsService,
    private authService: AuthService,
  ) {}

  public async showPopover({
    selectedItemId,
    callback,
    loadData,
    showImages,
    showSearch,
    placeholderLink,
    noneOptionLabel,
    dynamicCreationCallback,
    event,
    side,
    componentView,
    initialSearchText,
  }: {
    callback: (id: string, user: any, object: any) => void;
    event: any;
    selectedItemId?: string;
    loadData: (searchText: string) => Promise<any[]>;
    showImages?: boolean;
    showSearch?: boolean;
    placeholderLink?: string;
    noneOptionLabel?: string;
    dynamicCreationCallback?: (searchText: string) => void;
    side?: PopoverSide;
    componentView?: string;
    initialSearchText?: string;
  }) {
    const popover = await this.popoverCtrl.create({
      component: SearchablePopoverComponent,
      componentProps: {
        selectedItemId,
        showImages,
        showSearch,
        placeholderLink,
        dynamicCreationCallback,
        callback,
        loadData,
        componentView,
        initialSearchText,
      },
      showBackdrop: false,
      side,
      event,
    });

    popover.present();
  }

  public async users({
    callback,
    event,
    selectedUserId,
    side,
    dedupeInternalUsers,
    showNoneOption = true,
    showAssignToMeText,
  }: {
    callback: (id: string, name: string, user: any) => void;
    event: any;
    selectedUserId?: string;
    side?: PopoverSide;
    dedupeInternalUsers?: boolean;
    showNoneOption?: boolean;
    showAssignToMeText?: string;
  }) {
    this.showPopover({
      event,
      showImages: true,
      showSearch: true,
      side,
      selectedItemId: selectedUserId,
      callback,
      loadData: async (searchText: string) => {
        const where: WhereClause[] = [
          {
            field: 'isAutoAssignable',
            operator: '!=',
            value: false,
          },
          {
            field: 'status',
            operator: '==',
            value: 'active',
          },
          {
            field: 'name',
            operator: '!=',
            value: null,
          },
        ];

        if (searchText?.length) {
          where.push({
            field: 'name',
            operator: 'like',
            value: `%${searchText}%`,
          });
        }
        const result = await this.usersService.list({
          where,
          limit: 100,
          order: ['name'],
          descending: false,
          dedupeInternalUsers,
        });

        const data = [];

        if (
          showAssignToMeText?.length &&
          (!searchText?.length || showAssignToMeText.toLowerCase().includes(searchText.toLowerCase()))
        ) {
          data.push({
            title: showAssignToMeText,
            id: this.authService.userId,
            image: this.authService.userPhoto,
            object: this.authService.user,
          });
        }

        if (showNoneOption && (!searchText?.length || NONE_TEXT.toLowerCase().includes(searchText.toLowerCase()))) {
          data.push({
            title: NONE_TEXT,
            id: null,
            image: '',
            object: {},
          });
        }

        const connectedTeams = await this.slackService.getConnectedTeams();

        result.data.forEach((user) => {
          data.push({
            title: formatUserName(user, connectedTeams),
            id: user.id,
            image: user.photo,
            object: user,
          });
        });

        return data;
      },
    });
  }

  public async assignees({
    callback,
    event,
    selectedId,
    showPrimaryRepOption,
    showCreatedByUserOption,
    includeNonAgents = false,
    onlyMentionable = false,
    dedupeInternalUsers,
    showNoneOption,
    includeGroups = true,
    side,
  }: {
    callback: (id: string, name: string, user: any) => void;
    event: any;
    selectedId?: string;
    showPrimaryRepOption: boolean;
    showCreatedByUserOption: boolean;
    includeNonAgents?: boolean;
    onlyMentionable?: boolean;
    dedupeInternalUsers?: boolean;
    showNoneOption: boolean;
    includeGroups?: boolean;
    side?: PopoverSide;
  }) {
    this.showPopover({
      event,
      showImages: true,
      showSearch: true,
      side,
      selectedItemId: selectedId,
      callback,
      loadData: async (searchText: string) => {
        const where: WhereClause[] = [
          {
            field: 'status',
            operator: '==',
            value: 'active',
          },
          {
            field: 'name',
            operator: '!=',
            value: null,
          },
        ];

        if (!includeNonAgents) {
          where.push({
            field: 'isAutoAssignable',
            operator: '!=',
            value: false,
          });
        }

        if (onlyMentionable) {
          where.push({
            field: 'slackId',
            operator: '!=',
            value: null,
          });
        }

        const userGroupWhere: WhereClause[] = [
          {
            field: 'name',
            operator: '!=',
            value: null,
          },
          {
            field: 'isExternal',
            operator: '==',
            value: false,
          },
        ];

        if (searchText?.length) {
          where.push({
            field: 'name',
            operator: 'like',
            value: `%${searchText}%`,
          });
          userGroupWhere.push({
            field: 'name',
            operator: 'like',
            value: `%${searchText}%`,
          });
        }

        const [users, userGroups] = await Promise.all([
          this.usersService.list({
            where,
            limit: 100,
            order: ['name'],
            descending: false,
            dedupeInternalUsers,
          }),
          includeGroups
            ? this.userGroupsService.list({
                limit: 100,
                order: ['name'],
                descending: false,
                where: userGroupWhere,
              })
            : { data: [] },
        ]);

        const data = [];
        const noneText = 'No one';
        const primaryRepText = 'Primary support rep';
        const secondaryRepText = 'Secondary support rep';
        const assigneeManagerText = "Assignee's manager";
        const submitterManagerText = "Submitter's manager";
        const userCreatedCustomerText = 'User who created the customer';

        if (showNoneOption && (!searchText?.length || noneText.toLowerCase().includes(searchText.toLowerCase()))) {
          data.push({
            title: noneText,
            id: null,
            image: environment.assetsBaseUrl + '/imgs/1x1.png',
            object: {},
          });
        }

        if (
          showPrimaryRepOption &&
          (!searchText?.length ||
            primaryRepText.toLowerCase().includes(searchText.toLowerCase()) ||
            secondaryRepText.toLowerCase().includes(searchText.toLowerCase()))
        ) {
          data.push(
            {
              title: primaryRepText,
              id: SUPPORT_STEP_KEYWORDS.PRIMARY_SUPPORT_ASSIGNEE,
              image: environment.assetsBaseUrl + '/imgs/1x1.png',
              object: {},
            },
            {
              title: secondaryRepText,
              id: SUPPORT_STEP_KEYWORDS.SECONDARY_SUPPORT_ASSIGNEE,
              image: environment.assetsBaseUrl + '/imgs/1x1.png',
              object: {},
            },
            {
              title: assigneeManagerText,
              id: SUPPORT_STEP_KEYWORDS.ASSIGNEE_MANAGER,
              image: environment.assetsBaseUrl + '/imgs/1x1.png',
              object: {},
            },
            {
              title: submitterManagerText,
              id: SUPPORT_STEP_KEYWORDS.SUBMITTER_MANAGER,
              image: environment.assetsBaseUrl + '/imgs/1x1.png',
              object: {},
            },
          );
        }

        if (
          showCreatedByUserOption &&
          (!searchText?.length || userCreatedCustomerText.toLowerCase().includes(searchText.toLowerCase()))
        ) {
          data.push({
            title: userCreatedCustomerText,
            id: SUPPORT_STEP_KEYWORDS.CREATED_BY_USER,
            image: environment.assetsBaseUrl + '/imgs/1x1.png',
            object: {},
          });
        }

        userGroups.data.forEach((group) => {
          data.push({
            id: group.id,
            title: `${group.name} (Group)`,
            image: '',
            object: {
              type: SUPPORT_STEP_ASSIGNEE_TYPES.TEAM,
              ...group,
            },
          });
        });

        const connectedTeams = await this.slackService.getConnectedTeams();

        users.data.forEach((user) => {
          data.push({
            title: formatUserName(user, connectedTeams),
            id: user.id,
            image: user.photo,
            object: {
              type: SUPPORT_STEP_ASSIGNEE_TYPES.USER,
              ...user,
            },
          });
        });

        return data;
      },
    });
  }

  public async slackEntities({
    callback,
    event,
    selectedId,
    showPrimaryRepOption,
    showCreatedByUserOption,
    showNoneOption,
    side,
  }: {
    callback: (id: string, name: string, user: any) => void;
    event: any;
    selectedId?: string;
    showPrimaryRepOption: boolean;
    showCreatedByUserOption: boolean;
    includeNonAgents?: boolean;
    onlyMentionable?: boolean;
    dedupeInternalUsers?: boolean;
    showNoneOption: boolean;
    side?: PopoverSide;
  }) {
    this.showPopover({
      event,
      showImages: true,
      showSearch: true,
      side,
      selectedItemId: selectedId,
      callback,
      loadData: async (searchText: string) => {
        const where: WhereClause[] = [
          {
            field: 'status',
            operator: '==',
            value: 'active',
          },
          {
            field: 'name',
            operator: '!=',
            value: null,
          },
        ];

        where.push({
          field: 'slackId',
          operator: '!=',
          value: null,
        });

        const slackUserGroupWhere: WhereClause[] = [
          {
            field: 'name',
            operator: '!=',
            value: null,
          },
          {
            field: 'slackId',
            operator: '!=',
            value: null,
          },
        ];

        if (searchText?.length) {
          where.push({
            field: 'name',
            operator: 'like',
            value: `%${searchText}%`,
          });
          slackUserGroupWhere.push({
            field: 'name',
            operator: 'like',
            value: `%${searchText}%`,
          });
        }

        const [users, slackUserGroups] = await Promise.all([
          this.usersService.list({
            where,
            limit: 100,
            order: ['name'],
            descending: false,
          }),
          this.slackUserGroupsService.list({
            limit: 100,
            order: ['name'],
            descending: false,
            where: slackUserGroupWhere,
          }),
        ]);

        const data = [];
        const noneText = 'No one';
        const primaryRepText = 'Primary support rep';
        const secondaryRepText = 'Secondary support rep';
        const assigneeManagerText = "Assignee's manager";
        const submitterManagerText = "Submitter's manager";
        const userCreatedCustomerText = 'User who created the customer';

        if (showNoneOption && (!searchText?.length || noneText.toLowerCase().includes(searchText.toLowerCase()))) {
          data.push({
            title: noneText,
            id: null,
            image: environment.assetsBaseUrl + '/imgs/1x1.png',
            object: {},
          });
        }

        if (
          showPrimaryRepOption &&
          (!searchText?.length ||
            primaryRepText.toLowerCase().includes(searchText.toLowerCase()) ||
            secondaryRepText.toLowerCase().includes(searchText.toLowerCase()))
        ) {
          data.push(
            {
              title: primaryRepText,
              id: SUPPORT_STEP_KEYWORDS.PRIMARY_SUPPORT_ASSIGNEE,
              image: environment.assetsBaseUrl + '/imgs/1x1.png',
              object: {},
            },
            {
              title: secondaryRepText,
              id: SUPPORT_STEP_KEYWORDS.SECONDARY_SUPPORT_ASSIGNEE,
              image: environment.assetsBaseUrl + '/imgs/1x1.png',
              object: {},
            },
            {
              title: assigneeManagerText,
              id: SUPPORT_STEP_KEYWORDS.ASSIGNEE_MANAGER,
              image: environment.assetsBaseUrl + '/imgs/1x1.png',
              object: {},
            },
            {
              title: submitterManagerText,
              id: SUPPORT_STEP_KEYWORDS.SUBMITTER_MANAGER,
              image: environment.assetsBaseUrl + '/imgs/1x1.png',
              object: {},
            },
          );
        }

        if (
          showCreatedByUserOption &&
          (!searchText?.length || userCreatedCustomerText.toLowerCase().includes(searchText.toLowerCase()))
        ) {
          data.push({
            title: userCreatedCustomerText,
            id: SUPPORT_STEP_KEYWORDS.CREATED_BY_USER,
            image: environment.assetsBaseUrl + '/imgs/1x1.png',
            object: {},
          });
        }

        slackUserGroups.data.forEach((group) => {
          data.push({
            id: group.id,
            title: `${group.name} (Group)`,
            image: '',
            object: {
              type: SUPPORT_STEP_ASSIGNEE_TYPES.TEAM,
              ...group,
            },
          });
        });

        const connectedTeams = await this.slackService.getConnectedTeams();

        users.data.forEach((user) => {
          data.push({
            title: formatUserName(user, connectedTeams),
            id: user.id,
            image: user.photo,
            object: user,
          });
        });

        return data;
      },
    });
  }

  public async tags({
    callback,
    event,
    side,
  }: {
    callback: (id: string, name: any, object: any) => void;
    event: any;
    side?: PopoverSide;
  }) {
    this.showPopover({
      event,
      showImages: false,
      showSearch: true,
      placeholderLink: `/${routes.DASHBOARD}/tags`,
      dynamicCreationCallback: async (text: string) => {
        const tag = await this.tagsService.create({
          name: text,
          description: '',
          autoTaggingEnabled: false,
        });
        callback(tag.id, text, tag);
      },
      callback,
      side,
      loadData: async (searchText: string) => {
        const where: WhereClause[] = [];

        if (searchText?.length) {
          where.push({
            field: 'name',
            operator: 'like',
            value: `%${searchText}%`,
          });
        }

        const tagsResponse = await this.tagsService.list({
          where,
          limit: 100,
          order: ['name'],
          descending: false,
        });

        return tagsResponse.data.map((tag: Tag) => {
          return {
            title: tag.name,
            id: tag.id,
            object: tag,
          };
        });
      },
    });
  }

  public async customers({
    callback,
    event,
    selectedCustomerId,
    projectId,
    side,
  }: {
    callback: (id: string, customerName: string, customer: any) => void;
    event: any;
    selectedCustomerId?: string;
    projectId?: string;
    side?: PopoverSide;
  }) {
    this.showPopover({
      event,
      selectedItemId: selectedCustomerId,
      showImages: false,
      showSearch: true,
      side,
      callback,
      loadData: async (searchText: string) => {
        const where: WhereClause[] = [];

        if (projectId) {
          where.push({
            field: 'projectId',
            operator: '==',
            value: projectId,
          });
        }

        if (searchText?.length) {
          where.push({
            field: 'name',
            operator: 'like',
            value: `%${searchText}%`,
          });
        }

        const response = await this.customersService.list({
          where,
          limit: 100,
          order: ['name'],
          descending: false,
        });

        return response.data.map((customer: Customer) => {
          return {
            title: customer.name,
            id: customer.id,
            object: customer,
          };
        });
      },
    });
  }

  public async statuses({
    callback,
    event,
    selectedStatusId,
    showIntakeStatus = false,
    side,
  }: {
    callback: (id: string, user: any) => void;
    event: any;
    selectedStatusId?: string;
    showIntakeStatus?: boolean;
    side?: PopoverSide;
  }) {
    this.showPopover({
      event,
      selectedItemId: selectedStatusId,
      showImages: false,
      showSearch: false,
      callback,
      side,
      loadData: async () => {
        return STATUS_OPTIONS.filter((status) => showIntakeStatus || status.id !== CONVERSATION_STATUS.INTAKE);
      },
    });
  }

  public async priorities({
    callback,
    event,
    selectedPriority,
    side,
  }: {
    callback: (id: string, user: any) => void;
    event: any;
    selectedPriority?: string;
    side?: PopoverSide;
  }) {
    this.showPopover({
      event,
      selectedItemId: selectedPriority,
      showImages: false,
      showSearch: false,
      callback,
      side,
      loadData: async () => {
        return PRIORITY_OPTIONS;
      },
    });
  }

  public async externalTasks({
    callback,
    event,
    selectedOption,
    side,
  }: {
    callback: (id: string, user: any) => void;
    event: any;
    selectedOption?: string;
    side?: PopoverSide;
  }) {
    this.showPopover({
      event,
      selectedItemId: selectedOption,
      showImages: false,
      showSearch: false,
      callback,
      side,
      loadData: async () => {
        return EXTERNAL_TASK_FILTER_OPTIONS;
      },
    });
  }

  public async projectMemberRoles({
    callback,
    event,
    selectedRole,
    includeOwner = false,
    side,
  }: {
    callback: (id: ProjectMemberRole, user: any) => void;
    event: any;
    selectedRole?: string;
    includeOwner?: boolean;
    side?: PopoverSide;
  }) {
    this.showPopover({
      event,
      selectedItemId: selectedRole,
      showImages: false,
      showSearch: false,
      callback,
      side,
      loadData: async () => {
        return PROJECT_MEMBER_ROLE_OPTIONS.filter((role) => includeOwner || role.id !== PROJECT_MEMBER_ROLES.OWNER);
      },
    });
  }

  public async tenantUserRoles({
    callback,
    event,
    selectedRole,
    includeOwner = false,
    side,
  }: {
    callback: (id: ProjectMemberRole, user: any) => void;
    event: any;
    selectedRole?: string;
    includeOwner?: boolean;
    side?: PopoverSide;
  }) {
    this.showPopover({
      event,
      selectedItemId: selectedRole,
      showImages: false,
      showSearch: false,
      callback,
      side,
      loadData: async () => {
        return TENANT_USER_ROLE_OPTIONS.filter((role) => includeOwner || role.id !== TENANT_USER_ROLES.OWNER);
      },
    });
  }

  public async sourceTypes({
    callback,
    event,
    selectedSourceTypeId,
    side,
  }: {
    callback: (id: string, user: any) => void;
    event: any;
    selectedSourceTypeId?: string;
    side?: PopoverSide;
  }) {
    this.showPopover({
      event,
      selectedItemId: selectedSourceTypeId,
      showImages: false,
      showSearch: false,
      callback,
      side,
      loadData: async () => {
        return [
          {
            title: 'Slack',
            id: 'slack',
          },
          {
            title: 'Email',
            id: 'email',
          },
          {
            title: 'In-app',
            id: 'widget',
          },
          {
            title: 'Dashboard',
            id: 'dashboard',
          },
          {
            title: 'Microsoft Teams',
            id: 'microsoft-teams',
          },
        ];
      },
    });
  }

  public async conversations({
    callback,
    event,
    customerId,
    where = [],
  }: {
    callback: (id: string, _name: string, conversation: any) => void;
    event: any;
    customerId?: string;
    where?: WhereClause[];
  }) {
    this.showPopover({
      event,
      showImages: false,
      showSearch: true,
      callback,
      loadData: async (searchText: string) => {
        const fullWhere: WhereClause[] = [...where];

        if (customerId) {
          fullWhere.push({
            field: 'customerId',
            operator: '==',
            value: customerId,
          });
        }

        const response = await this.conversationsService.list({
          where: fullWhere,
          limit: 100,
          search: searchText || undefined,
          order: ['createdAt'],
          descending: true,
        });

        return response.data.map((conversation: Conversation) => {
          return {
            title: `[${conversation.friendlyId}] ${conversation.title}`,
            id: conversation.id,
            object: conversation,
          };
        });
      },
    });
  }

  public async templates({
    callback,
    event,
    selectedTemplateId,
    side,
    templateType,
    componentView,
    showNoneOption = true,
  }: {
    callback: (id: string, title: string, template: any) => void;
    event: any;
    selectedTemplateId?: string;
    side?: PopoverSide;
    templateType?: 'slack' | 'email';
    componentView?: string;
    showNoneOption?: boolean;
  }) {
    this.showPopover({
      event,
      selectedItemId: selectedTemplateId,
      showImages: false,
      showSearch: true,
      side,
      placeholderLink: `/${routes.DASHBOARD}/${routes.OUTBOUND}/templates`,
      componentView,
      callback,
      loadData: async (searchText: string) => {
        const params = templateType
          ? {
              type: templateType,
            }
          : {};

        const response: any = await this.apiService.getPromise(`/templates`, params);
        const data = [];

        if (showNoneOption && (!searchText?.length || NONE_TEXT.toLowerCase().includes(searchText.toLowerCase()))) {
          data.push({
            title: NONE_TEXT,
            id: null,
            image: environment.assetsBaseUrl + '/imgs/1x1.png',
            object: {},
          });
        }

        response?.rows.map((template: Template) => {
          if (!searchText?.length || template.name.toLowerCase().includes(searchText.trim().toLowerCase())) {
            data.push({
              title: template.name,
              id: template.id,
              object: template,
            });
          }
        });
        return data;
      },
    });
  }

  public async channelMembers({
    callback,
    event,
    customerId,
    side,
  }: {
    callback: (id: string, name: string, member: any) => void;
    event: any;
    customerId: string;
    side?: PopoverSide;
  }) {
    this.showPopover({
      event,
      showImages: true,
      showSearch: false,
      callback,
      side,
      loadData: async (_searchText: string) => {
        const response: any = await this.apiService.getPromise(`/customers/${customerId}/members`);

        return response?.map((member: any) => {
          return {
            title: member.user.name,
            id: member.user.id,
            image: member.user.photo,
            object: member,
          };
        });
      },
    });
  }

  public async mentionableUsers({
    callback,
    event,
    selectedUserId,
    side,
    dedupeInternalUsers,
  }: {
    callback: (id: string, name: string, user: any) => void;
    event: any;
    selectedUserId?: string;
    side?: PopoverSide;
    dedupeInternalUsers?: boolean;
  }) {
    this.showPopover({
      event,
      showImages: true,
      showSearch: true,
      side,
      selectedItemId: selectedUserId,
      callback,
      loadData: async (searchText: string) => {
        const where: WhereClause[] = [
          {
            field: 'slackId',
            operator: '!=',
            value: null,
          },
          {
            field: 'status',
            operator: '==',
            value: 'active',
          },
          {
            field: 'name',
            operator: '!=',
            value: null,
          },
        ];

        if (searchText?.length) {
          where.push({
            field: 'name',
            operator: 'like',
            value: `%${searchText}%`,
          });
        }
        const result = await this.usersService.list({
          includeExternalUsers: true,
          where,
          limit: 100,
          order: ['name'],
          descending: false,
          dedupeInternalUsers,
        });

        const data = [];

        const connectedTeams = await this.slackService.getConnectedTeams();

        result.data.forEach((user) => {
          data.push({
            title: formatUserName(user, connectedTeams),
            id: user.id,
            image: user.photo,
            object: user,
          });
        });

        return data;
      },
    });
  }

  public async allUsers({
    callback,
    event,
    selectedUserId,
    side,
    dedupeInternalUsers,
    dynamicCreationCallback,
  }: {
    callback: (id: string, name: string, user: any) => void;
    event: any;
    selectedUserId?: string;
    side?: PopoverSide;
    dedupeInternalUsers?: boolean;
    dynamicCreationCallback?: (searchText: string) => void;
  }) {
    this.showPopover({
      event,
      showImages: true,
      showSearch: true,
      side,
      selectedItemId: selectedUserId,
      callback,
      dynamicCreationCallback,
      loadData: async (searchText: string) => {
        const where: WhereClause[] = [
          {
            field: 'name',
            operator: '!=',
            value: null,
          },
        ];

        const result = await this.usersService.list({
          limit: 100,
          includeExternalUsers: true,
          dedupeInternalUsers,
          where,
          order: ['name', 'email'],
          search: searchText,
          descending: false,
        });

        const data = [];

        const connectedTeams = await this.slackService.getConnectedTeams();

        result.data.forEach((user) => {
          data.push({
            title: formatUserName(user, connectedTeams),
            id: user.id,
            image: user.photo,
            object: user,
          });
        });
        return data;
      },
    });
  }

  public async ticketTypes({
    callback,
    event,
    selectedTicketTypeId,
    showNoneOption = true,
    projectId,
    side,
  }: {
    callback: (id: string, title, data: TicketType) => void;
    event: any;
    selectedTicketTypeId?: string;
    showNoneOption?: boolean;
    projectId?: string;
    side?: PopoverSide;
  }) {
    this.showPopover({
      event,
      selectedItemId: selectedTicketTypeId,
      showImages: false,
      showSearch: true,
      side,
      placeholderLink: `/${routes.DASHBOARD}/${routes.SETTINGS}/${routes.TICKET_TYPES}`,
      callback,
      loadData: async (searchText: string) => {
        const where: WhereClause[] = [];

        if (projectId) {
          where.push({
            field: 'projectId',
            operator: '==',
            value: projectId,
          });
        }

        if (searchText?.length) {
          where.push({
            field: 'name',
            operator: 'like',
            value: `%${searchText}%`,
          });
        }

        const response = await this.ticketTypesService.list({
          where,
          limit: 100,
          order: ['name'],
          descending: false,
        });

        const data = [];

        if (showNoneOption && (!searchText?.length || NONE_TEXT.toLowerCase().includes(searchText.toLowerCase()))) {
          data.push({
            title: NONE_TEXT,
            id: null,
            image: environment.assetsBaseUrl + '/imgs/1x1.png',
            object: {},
          });
        }

        response.data.forEach((ticketType: TicketType) => {
          data.push({
            title: ticketType.name,
            id: ticketType.id,
            object: ticketType,
          });
        });

        return data;
      },
    });
  }

  public async projects({
    callback,
    event,
    selectedProjectId,
    showNoneOption = true,
    showImages = false,
    noneOptionLabel,
    side,
  }: {
    callback: (id: string, title, data: Project) => void;
    event: any;
    selectedProjectId?: string;
    showNoneOption?: boolean;
    showImages?: boolean;
    noneOptionLabel?: string;
    side?: PopoverSide;
  }) {
    this.showPopover({
      event,
      selectedItemId: selectedProjectId,
      showImages,
      showSearch: true,
      side,
      placeholderLink: `/${routes.DASHBOARD}/${routes.SETTINGS}/${routes.PROJECTS}`,
      noneOptionLabel,
      callback,
      loadData: async (searchText: string) => {
        const where: WhereClause[] = [];

        if (searchText?.length) {
          where.push({
            field: 'name',
            operator: 'like',
            value: `%${searchText}%`,
          });
        }

        const response = await this.projectsService.list({
          where,
          limit: 100,
          order: ['name'],
          descending: false,
        });

        const data = [];

        if (showNoneOption && (!searchText?.length || NONE_TEXT.toLowerCase().includes(searchText.toLowerCase()))) {
          data.push({
            title: noneOptionLabel || NONE_TEXT,
            id: null,
            image: environment.assetsBaseUrl + '/imgs/1x1.png',
            object: {},
          });
        }

        response.data.forEach((item) => {
          data.push({
            title: item.name,
            id: item.id,
            object: item,
            image: item.supportLogo,
          });
        });

        return data;
      },
    });
  }

  public async automations({
    callback,
    event,
    projectId,
    selectedAutomationId,
    showNoneOption,
    trigger,
    side,
  }: {
    callback: (id: string, name: string, data: Automation) => void;
    event: any;
    projectId?: string;
    selectedAutomationId?: string;
    showNoneOption?: boolean;
    side?: PopoverSide;
    trigger?: {
      type?: string | string[];
      objectType?: string | string[];
      event?: string | string[];
    };
  }) {
    this.showPopover({
      event,
      selectedItemId: selectedAutomationId,
      showImages: false,
      showSearch: true,
      side,
      placeholderLink: `/${routes.DASHBOARD}/${routes.SETTINGS}/${routes.AUTOMATIONS}`,
      callback,
      loadData: async (searchText: string) => {
        const where: WhereClause[] = [];

        if (projectId) {
          where.push({
            field: 'projectId',
            operator: '==',
            value: projectId,
          });
        }

        if (searchText?.length) {
          where.push({
            field: 'name',
            operator: 'like',
            value: `%${searchText}%`,
          });
        }

        if (trigger?.type) {
          if (Array.isArray(trigger.type)) {
            where.push({
              field: 'trigger.type',
              operator: 'in',
              value: trigger.type,
            });
          } else {
            where.push({
              field: 'trigger.type',
              operator: '==',
              value: trigger.type,
            });
          }
        }

        if (trigger?.objectType) {
          if (Array.isArray(trigger.objectType)) {
            where.push({
              field: 'trigger.data.objectType',
              operator: 'in',
              value: trigger.objectType,
            });
          } else {
            where.push({
              field: 'trigger.data.objectType',
              operator: '==',
              value: trigger.objectType,
            });
          }
        }

        if (trigger?.event) {
          if (Array.isArray(trigger.event)) {
            where.push({
              field: 'trigger.data.event',
              operator: 'in',
              value: trigger.event,
            });
          } else {
            where.push({
              field: 'trigger.data.event',
              operator: '==',
              value: trigger.event,
            });
          }
        }

        const response = await this.automationsService.list({
          where,
          limit: null,
          order: ['name'],
          descending: false,
        });

        const data = [];

        if (showNoneOption && (!searchText?.length || NONE_TEXT.toLowerCase().includes(searchText.toLowerCase()))) {
          data.push({
            title: NONE_TEXT,
            id: null,
            image: environment.assetsBaseUrl + '/imgs/1x1.png',
            object: {},
          });
        }

        response.data.forEach((automation: Automation) => {
          data.push({
            title: automation.name,
            id: automation.id,
            object: automation,
          });
        });

        return data;
      },
    });
  }

  public async slas({
    callback,
    event,
    selectedSlaId,
    side,
  }: {
    callback: (id: string, title: string, sla: any) => void;
    event: any;
    selectedSlaId?: string;
    side?: PopoverSide;
  }) {
    this.showPopover({
      event,
      selectedItemId: selectedSlaId,
      showImages: false,
      showSearch: false,
      side,
      callback,
      loadData: async (_searchText: string) => {
        const result: any = await this.apiService.postPromise('/support-step-rulesets/list', {
          order: ['createdAt'],
          descending: false,
        });

        const data = [
          {
            title: 'Default',
            id: null,
            object: null,
          },
        ];

        result.data.forEach((sla) => {
          data.push({
            title: sla.name,
            id: sla.id,
            object: sla,
          });
        });

        return data;
      },
    });
  }

  public async externalCrmAccounts({
    callback,
    event,
    selectedAccountId,
    initialSearchText,
    side,
  }: {
    callback: (id: string, title: string, sla: any) => void;
    event: any;
    selectedAccountId?: string;
    side?: PopoverSide;
    initialSearchText?: string;
  }) {
    this.showPopover({
      event,
      selectedItemId: selectedAccountId,
      showImages: false,
      showSearch: true,
      side,
      initialSearchText,
      callback,
      loadData: async (searchText: string) => {
        const where: WhereClause[] = [];

        const options: any = {
          limit: 100,
          order: ['name'],
        };

        if (searchText) {
          options.where = [
            {
              field: 'name',
              operator: 'like',
              value: `%${searchText}%`,
            },
          ];
        }

        const response: ListResponse<ExternalCrmAccount> = await this.apiService.postPromise(
          '/external-crm/accounts/list',
          options,
        );

        const data = [];

        response.data.forEach((account: ExternalCrmAccount) => {
          data.push({
            title: account.name,
            id: account.id,
            object: account,
          });
        });

        return data;
      },
    });
  }

  public async serviceAccounts({
    callback,
    event,
    selectedServiceAccountId,
    showNoneOption = true,
    side,
  }: {
    callback: (id: string, title, data: TicketType) => void;
    event: any;
    selectedServiceAccountId?: string;
    showNoneOption?: boolean;
    side?: PopoverSide;
  }) {
    this.showPopover({
      event,
      selectedItemId: selectedServiceAccountId,
      showImages: false,
      showSearch: true,
      side,
      placeholderLink: `/${routes.DASHBOARD}/${routes.ADMIN}/${routes.SERVICE_ACCOUNTS}`,
      callback,
      loadData: async (searchText: string) => {
        const where: WhereClause[] = [];

        if (searchText?.length) {
          where.push({
            field: 'name',
            operator: 'like',
            value: `%${searchText}%`,
          });
        }

        const response = await this.serviceAccountsService.list({
          where,
          limit: 100,
          order: ['name'],
          descending: false,
        });

        const data = [];

        if (showNoneOption && (!searchText?.length || NONE_TEXT.toLowerCase().includes(searchText.toLowerCase()))) {
          data.push({
            title: NONE_TEXT,
            id: null,
            image: environment.assetsBaseUrl + '/imgs/1x1.png',
            object: {},
          });
        }

        response.data.forEach((item) => {
          data.push({
            title: item.name,
            id: item.id,
            object: item,
          });
        });

        return data;
      },
    });
  }

  public async slackChannels({
    callback,
    event,
    selectedSlackChannelId,
    side,
  }: {
    callback: (id: string, name: string, data: Automation) => void;
    event: any;
    selectedSlackChannelId?: string;
    showNoneOption?: boolean;
    side?: PopoverSide;
  }) {
    this.showPopover({
      event,
      selectedItemId: selectedSlackChannelId,
      showImages: false,
      showSearch: true,
      side,
      callback,
      loadData: async (searchText: string) => {
        const channels = await this.slackService.getConnectedChannels(true);

        return channels
          .filter((channel) => !searchText || channel.name.toLowerCase().includes(searchText.toLowerCase()))
          .map((channel) => ({
            title: channel.name,
            id: channel.id,
            object: channel,
          }));
      },
    });
  }

  public async slackTeams({
    callback,
    event,
    selectedSlackTeamId,
    side,
  }: {
    callback: (id: string, name: string, data: Automation) => void;
    event: any;
    selectedSlackTeamId?: string;
    showNoneOption?: boolean;
    side?: PopoverSide;
  }) {
    this.showPopover({
      event,
      selectedItemId: selectedSlackTeamId,
      showImages: false,
      showSearch: true,
      side,
      callback,
      loadData: async (searchText: string) => {
        const teamsResponse: ListResponse<{ id: string; name: string; icon: string }> =
          await this.apiService.postPromise('/slack-teams/list', {
            limit: 100,
            order: ['name'],
            descending: false,
          });

        return teamsResponse.data
          .filter((team) => !searchText || team.name.toLowerCase().includes(searchText.toLowerCase()))
          .map((team) => ({
            title: team.name,
            id: team.id,
            object: team,
          }));
      },
    });
  }

  public async slackUserGroups({
    callback,
    event,
    selectedSlackGroupId,
    showNoneOption,
    side,
  }: {
    callback: (id: string, name: string, data: Automation) => void;
    event: any;
    selectedSlackGroupId?: string;
    showNoneOption?: boolean;
    side?: PopoverSide;
  }) {
    this.showPopover({
      event,
      selectedItemId: selectedSlackGroupId,
      showImages: false,
      showSearch: true,
      side,
      callback,
      loadData: async (searchText: string) => {
        const where: WhereClause[] = [];

        if (searchText?.length) {
          where.push({
            field: 'name',
            operator: 'like',
            value: `%${searchText}%`,
          });
        }

        const response = await this.slackUserGroupsService.list({
          limit: 100,
          order: ['name'],
          descending: false,
          where,
        });

        const data = [];

        if (showNoneOption && (!searchText?.length || NONE_TEXT.toLowerCase().includes(searchText.toLowerCase()))) {
          data.push({
            title: NONE_TEXT,
            id: null,
            image: environment.assetsBaseUrl + '/imgs/1x1.png',
            object: {},
          });
        }

        response.data.forEach((item: SlackGroup) => {
          data.push({
            title: `${item.name} - @${item.slackHandle}`,
            id: item.slackId,
            object: item,
          });
        });

        return data;
      },
    });
  }

  public async scimGroups({
    callback,
    event,
    selectedScimGroupId,
    showNoneOption,
    side,
  }: {
    callback: (id: string, name: string, data: Automation) => void;
    event: any;
    selectedScimGroupId?: string;
    showNoneOption?: boolean;
    side?: PopoverSide;
  }) {
    this.showPopover({
      event,
      selectedItemId: selectedScimGroupId,
      showImages: false,
      showSearch: true,
      side,
      callback,
      loadData: async (searchText: string) => {
        const response: any[] = await this.apiService.getPromise('/scim-groups');
        const data = [];

        if (showNoneOption && (!searchText?.length || NONE_TEXT.toLowerCase().includes(searchText.toLowerCase()))) {
          data.push({
            title: NONE_TEXT,
            id: null,
            image: environment.assetsBaseUrl + '/imgs/1x1.png',
            object: {},
          });
        }

        response
          .filter((item: any) => !searchText || item.name.toLowerCase().includes(searchText.toLowerCase()))
          .forEach((item: any) => {
            data.push({
              title: item.name,
              id: item.idpId,
              object: item,
            });
          });

        return data;
      },
    });
  }

  public async dynamicUserFieldValues({
    callback,
    event,
    selectedDynamicUserFieldValueId,
    showNoneOption,
    field,
    side,
  }: {
    callback: (id: string, name: string, data: Automation) => void;
    event: any;
    selectedDynamicUserFieldValueId?: string;
    field: string;
    showNoneOption?: boolean;
    side?: PopoverSide;
  }) {
    this.showPopover({
      event,
      selectedItemId: selectedDynamicUserFieldValueId,
      showImages: false,
      showSearch: true,
      side,
      callback,
      loadData: async (searchText: string) => {
        const response: any[] = await this.apiService.postPromise('/users/field-values', {
          field,
          search: searchText,
        });
        const data = [];

        if (showNoneOption && (!searchText?.length || NONE_TEXT.toLowerCase().includes(searchText.toLowerCase()))) {
          data.push({
            title: NONE_TEXT,
            id: null,
            image: environment.assetsBaseUrl + '/imgs/1x1.png',
            object: {},
          });
        }

        response.forEach((item: any) => {
          data.push({
            title: item,
            id: item,
            object: item,
          });
        });

        return data;
      },
    });
  }

  public async entities({
    callback,
    event,
    selectedEntityId,
    includeServiceAccounts = false,
    type,
    isExternal,
    side,
    excludeIds,
    dynamicCreationCallback,
  }: {
    callback: (id: string, name: string, user: any) => void;
    event: any;
    selectedEntityId?: string;
    includeServiceAccounts?: boolean;
    isExternal?: boolean;
    type?: string;
    side?: PopoverSide;
    dynamicCreationCallback?: (searchText: string) => void;
    excludeIds?: string[];
  }) {
    this.showPopover({
      event,
      showImages: true,
      showSearch: true,
      side,
      selectedItemId: selectedEntityId,
      callback,
      dynamicCreationCallback,
      loadData: async (searchText: string) => {
        const where: WhereClause[] = [
          {
            field: 'name',
            operator: '!=',
            value: null,
          },
        ];

        if (searchText?.length) {
          where.push({
            field: 'name',
            operator: 'like',
            value: `%${searchText}%`,
          });
        }

        if (!includeServiceAccounts) {
          where.push({
            field: 'isServiceAccount',
            operator: '!=',
            value: true,
          });
        }

        if (isExternal != null) {
          where.push({
            field: 'isExternal',
            operator: '==',
            value: isExternal,
          });
        }

        if (type) {
          where.push({
            field: 'type',
            operator: '==',
            value: type,
          });
        }

        if (excludeIds?.length) {
          where.push({
            field: 'id',
            operator: 'notIn',
            value: excludeIds,
          });
        }

        const result: ListResponse<Entity> = await this.apiService.postPromise('/entities/list', {
          limit: 100,
          where,
          order: ['type', 'name', 'email'],
          descending: false,
        });

        const data = [];

        result.data.forEach((entity) => {
          data.push({
            title: entity.name,
            id: entity.id,
            image: entity.photo,
            object: entity,
          });
        });
        return data;
      },
    });
  }

  public async customFieldOptions({
    callback,
    event,
    field,
    context,
    selectedValue,
    side,
    dynamicCreationCallback,
  }: {
    callback: (id: string, name: string, user: any) => void;
    event: any;
    field: any;
    context: any;
    selectedValue?: string;
    side?: PopoverSide;
    dynamicCreationCallback?: (searchText: string) => void;
  }) {
    this.showPopover({
      event,
      showImages: false,
      showSearch: true,
      side,
      selectedItemId: selectedValue,
      callback,
      dynamicCreationCallback,
      loadData: async (searchText: string) => {
        const cleanQuery = searchText?.trim();
        let options: { label: string; value: string }[] = [];

        if (field.externalSelect != null) {
          const selectOptions: { label: string; value: string }[] = await this.apiService.postPromise(
            `/automations/get-external-select-options`,
            {
              query: searchText,
              functionName: field.externalSelect,
              context,
            },
          );

          options = selectOptions;
        } else {
          options = (
            field.options?.map((option: string | { label: string; value: string }) => {
              if (typeof option === 'string') {
                return {
                  label: option,
                  value: option,
                };
              }

              return option;
            }) ?? []
          ).filter(
            (option: { label: string; value: string }) =>
              !cleanQuery ||
              option.label.toLowerCase().includes(cleanQuery.toLowerCase()) ||
              option.value.toLowerCase().includes(cleanQuery.toLowerCase()),
          );
        }

        return options.map((option) => {
          return {
            title: option.label,
            id: option.value,
            object: { label: option.label },
          };
        });
      },
    });
  }

  public async customFields({
    callback,
    event,
    field,
    context,
    selectedValue,
    side,
    dynamicCreationCallback,
  }: {
    callback: (id: string, name: string, user: any) => void;
    event: any;
    field: any;
    context: any;
    selectedValue?: string;
    side?: PopoverSide;
    dynamicCreationCallback?: (searchText: string) => void;
  }) {
    this.showPopover({
      event,
      showImages: false,
      showSearch: true,
      side,
      selectedItemId: selectedValue,
      callback,
      dynamicCreationCallback,
      loadData: async (searchText: string) => {
        const cleanQuery = searchText?.trim();

        const ticketTypesResponse: ListResponse<TicketTypeField> = await this.apiService.postPromise(
          '/custom-fields/list',
          {
            limit: null,
            order: ['name'],
            descending: false,
            where: [
              {
                field: 'type',
                operator: 'in',
                value: ['single-select', 'multi-select'],
              },
            ],
          },
        );

        // Filter ticket types by the existence of custom fields and return all custom fields as options
        return ticketTypesResponse.data
          .map((f) => {
            return {
              title: `[${f.ticketType?.name}] ${f.name}`, // Show the ticket type name in the label to disambiguate
              id: f.id,
              object: {
                ...f,
                ticketTypeName: f.ticketType?.name,
              },
            };
          })
          .filter((f) => !cleanQuery || f.title.toLowerCase().includes(cleanQuery.toLowerCase()));
      },
    });
  }

  public async externalSelectFunctions({
    callback,
    event,
    selectedValue,
    side,
    dynamicCreationCallback,
  }: {
    callback: (id: string, name: string, user: any) => void;
    event: any;
    selectedValue?: string;
    side?: PopoverSide;
    dynamicCreationCallback?: (searchText: string) => void;
  }) {
    this.showPopover({
      event,
      showImages: false,
      showSearch: true,
      side,
      selectedItemId: selectedValue,
      callback,
      dynamicCreationCallback,
      loadData: async (searchText: string) => {
        const automationFunctions: any = await this.apiService.getPromise('/automations/functions/active');

        return (
          automationFunctions?.exportedFunctionsByType?.externalSelect?.flatMap((functionName) => {
            if (searchText && !functionName.includes(searchText)) {
              return [];
            }

            return [
              {
                title: functionName,
                id: functionName,
                object: { label: functionName },
              },
            ];
          }) ?? []
        );
      },
    });
  }

  public async accounts({
    callback,
    event,
    selectedAccountId,
    side,
  }: {
    callback: (id: string, name: string, user: any) => void;
    event: any;
    selectedAccountId?: string;
    side?: PopoverSide;
  }) {
    this.showPopover({
      event,
      selectedItemId: selectedAccountId,
      showImages: false,
      showSearch: true,
      side,
      callback,
      loadData: async (searchText: string) => {
        const cleanQuery = searchText?.trim();

        const accounts: ListResponse<Account> = await this.apiService.postPromise('/accounts/list', {
          where: cleanQuery ? [{ field: 'name', operator: 'like', value: `%${cleanQuery}%` }] : [],
        });

        return accounts.data.map((account) => {
          return {
            title: account.name,
            id: account.id,
            object: account,
          };
        });
      },
    });
  }

  public async dateValues({
    callback,
    event,
    selectedDateValueId,
    side,
  }: {
    callback: (id: string, name: any, object: any) => void;
    event: any;
    selectedDateValueId?: string;
    side?: PopoverSide;
  }) {
    this.showPopover({
      event,
      selectedItemId: selectedDateValueId,
      showImages: false,
      showSearch: true,
      callback,
      side,
      loadData: async (searchText: string) => {
        const cleanQuery = searchText?.trim();
        return DATE_OPTIONS.filter((dateOption) => {
          return !cleanQuery || dateOption.label.toLowerCase().includes(cleanQuery.toLowerCase());
        }).map((dateOption) => {
          return {
            title: dateOption.label,
            id: dateOption.label,
            object: dateOption,
          };
        });
      },
    });
  }
  public async inboxViews({
    callback,
    event,
    selectedInboxViewId,
    side,
  }: {
    callback: (id: string, name: string, user: any) => void;
    event: any;
    selectedInboxViewId?: string;
    side?: PopoverSide;
  }) {
    this.showPopover({
      event,
      selectedItemId: selectedInboxViewId,
      showImages: false,
      showSearch: true,
      callback,
      side,
      loadData: async (searchText: string) => {
        const cleanQuery = searchText?.trim();
        const savedFilters: Partial<SavedFilter>[] = await this.apiService.getPromise('/saved-filters?view=inbox');

        const transformedSavedFilters = savedFilters.map((savedFilter) => {
          return {
            title: savedFilter.name,
            id: `${PATHS.INBOX}/filter/${savedFilter.id}`,
            object: savedFilter,
          };
        });

        const staticSubpages = INBOX_SUBPAGES.map((subpage) => {
          return {
            title: subpage.title,
            id: subpage.url,
            object: subpage,
          };
        });

        return [...staticSubpages, ...transformedSavedFilters].filter((subpage) => {
          return !cleanQuery || subpage.title.toLowerCase().includes(cleanQuery.toLowerCase());
        });
      },
    });
  }
}
